footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    
    background: rgba(135, 173, 182, 1.0);
    width: 100vw;
    height: 20vh;

    bottom: 0;
    right: 0;

    font-weight: 300;
    font-size: 1.3rem;
}

footer div {
    height: 20vh;
    width: 10vw;
    margin-right: 5vw;
}

footer i {
    font-size: 1.4em;
    margin-right: -5vw;
}

footer h4, 
footer h4 > a {
    color: white;
}

footer h4 {
    display: flex;
    flex-direction: row;
    margin-top: 1vh;
}

footer a:hover {
    color: #ffa6a0;
}

small {
    color: floralwhite;
}
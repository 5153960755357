@import url(https://fonts.googleapis.com/css2?family=Raleway&display=swap);
:root {
  --green: #a9e8b6;
  --yellow: #ffd693;
  --pink: #ffa6a0;
  --purple: #43447a;
}

* {
  width: 100%;
  overflow-x: hidden;
  font-family: 'Raleway', serif;
  color: black;
  margin: 0;
  padding: 0;
}

.flex-row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
}

.flex-column {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
}

body {
  background: #F6FAFC;
  color: #43447a;
  color: var(--purple);
  font-weight: lighter;
  margin: 0;
}

a {
  text-decoration: none;
  color: black;
}

a:hover {
  color: #ffa6a0;
  color: var(--pink);
}

.graph {
  width: 100%;
  height: 100%;
}

.App {
  width: 100vw;
  height: 760px;

  margin-top: -50px;

  overflow: hidden;
}

.button-subheading {
  margin-top: 12px;
  padding: 5px;
  overflow-y: hidden;
  font-size: 1.2em;
  width: 20vw;
}

.fns {
  height: 90px;
  width: 79vw;
  margin-left: 1vw;
  background: #b9D7EA;
}

section.visual {
  width: 79vw;
  margin-left: 1vw;
  height: 570px;
  border: 1px solid grey;
  border-top: none;
}

.main-visual {
  height: 855px;
}

section.interface {
  width: 22vw;
  height: 700px;
  margin-top: 55px;
  margin-right: 1vw;
  background: #b9D7EA;
}

div.buttons {
  padding-left: 2vw;
}

div.page {
  height: 180px;
}

div.tabs {
  background: #F6FAFC;
  height: 56px;
  width: 21vw;
}

section.interface div.head {
  height: 40px;
  margin-bottom: -3vh;
  width: 10vw;
}

.hide {
  display: none;
  max-height: 0;
}

#presets {
  height: 690px;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

#algorithms, #algorithms div {
  height: 690px;
}

@media screen and (max-width: 576px) {
    .main {
        display: none;
    }

    div.mobile {
        display: -webkit-flex;
        display: flex;
     
        height: 600px;
        width: 80vw;

        margin: auto;
        margin-top: 80px;
    }

    div.mobile i {
        font-size: 8rem;
        margin-top: 100px;
        margin-left: 20vw;
    }
}

@media screen and (min-width: 577px) and (max-width: 768px) {
    section.interface,
    div.tabs {
        width: 55vw;
        overflow: hidden;
    }

    section.visual,
    div.fns {
        width: 61vw;
    }

    div#presets h4.button-subheading,
    div#algorithms h4.button-subheading { /* TODO */
        width: 55vw;
    }
}


@media screen and (min-width: 769px) and (max-width: 992px) {
    section.interface {
        width: 35vw;
        overflow-x: hidden;
        overflow-y: auto;
    }

    div.tabs {
        width: 35vw;
        padding-left: 1vw;
        overflow: hidden;
    }
    
    section.visual,
    div.fns {
        width: 70vw;
    }

    div#algorithms h4.button-subheading {
        width: 60vw;
        font-size: 1.08em;
    }
}

@media screen and (min-width: 993px) and (max-width: 1800px) {
    section.interface {
        width: 30vw;
        overflow-x: hidden;
        overflow-y: auto;
    }

    div.tabs {
        width: 30vw;
        padding-left: 1vw;
        overflow: hidden;
    }
    
    section.visual,
    div.fns {
        width: 73vw;
    }

    div#algorithms h4.button-subheading {
        width: 60vw;
        font-size: 1.1em;
    }
}
button {
    height: 35px;
    width: 4.5vw;
    border-radius: 11px;
    border: white;
    margin-top: 27px;
    margin-left: 0.5vw;
}

button:hover, button:focus {
    cursor: pointer;
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
}

#algorithms button {
    width: 13vw;
    height: 55px;
    font-size: 1.1rem;
}

#presets button {
    height: 55px; 
    width: 12vw;
    font-size: 1.0rem;
}

#presets button:last-child {
    width: 7vw;
    margin-left: 4vw;
}

.fns button {
    height: 40px;
    width: 9vw;
    margin-right: 4vw;
    font-size: 1.2rem;
}

.tabs button {
    height: 30px;
    width: 7vw;
    margin-right: 1vw;
    padding-bottom: 5px;
    border-radius: 11px 11px 0 0;
}

button.inactiveTab {
    background: #b9D7EA;
}

button.activeTab {
    background: rgb(239, 239, 239);
}

button.app-button {
    background: #33e958;
    -webkit-filter: brightness(0.833);
            filter: brightness(0.833);
    width: 12vw;
    height: 6vh;
    font-size: 1.5rem;
}

button.app-button:hover {
    cursor: pointer;
}

button.app-button a:hover {
    color: black;
}

button.redirect-mobile {
    display: none;
}
@media screen and (max-width: 576px) {
    button.app-button {
        width: 45vw;
        height: 60px;
        margin: auto;
    }
}

@media screen and (min-width: 577px) and (max-width: 768px) {
    button.redirect-desktop {
        display: none;
    }

    button.redirect-mobile {
        display: block;
        height: 70px;
        width: 27vw;
    }

    div.fns button {
        height: 50px;
        width: 10vw;

        margin: 0;
        margin-right: 1vw;
        margin-top: 3vw;
        font-size: 0.9em;
    }

    div.tabs button {
        width: 14vw;
        margin-right: 2vw;
    }

    div#presets button {
        width: 25vw;
        margin-left: 4vw;
        font-size: 1.0em;
    }

    div#presets button:last-child {
        width: 13vw;
    }

    div#algorithms button {
        width: 25vw;
        margin-left: 3vw;
        font-size: 0.8em;
    }

    div#algorithms h4.button-subheading {
        width: 55vw;
        font-size: 1.0em;
        margin-top: 3vw;
    }
}

@media screen and (min-width: 769px) and (max-width: 992px) {
    button.redirect-desktop {
        height: 50px;
        width: 23vw;
    }

    div.fns button {
        height: 50px;
        width: 10vw;

        margin: 0;
        margin-right: 3vw;
        margin-top: 3vw;
    }

    div.tabs button {
        width: 11vw;
        margin-right: 1vw;
    }

    div#presets button {
        width: 20vw;
        margin-left: 1vw;
        font-size: 0.9em;
    }

    div#presets button:last-child {
        width: 10vw;
    }

    div#algorithms button {
        width: 20vw;
        margin-left: 1vw;
    }
}

@media screen and (min-width: 993px) and (max-width: 1800px) {
    button.redirect-desktop {
        width: 15vw;
    }

    div.fns button {
        height: 40px;
        width: 12vw;

        margin: 0;
        margin-right: 2vw;
        margin-top: 2vw;
    }

    div.tabs button {
        width: 8vw;
        margin-right: 1vw;
        font-size: 0.9em;
    }

    div#presets button {
        width: 15vw;
        margin-left: 2vw;
        font-size: 1.0em;
    }

    div#presets button:last-child {
        width: 10vw;
    }

    div#algorithms button {
        width: 12vw;
        margin-left: 2vw;
    }
}
div#import-form {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
}

div#import-form textarea {
    width: 8vw;
}
@media screen and (min-width: 577px) and (max-width: 768px) {
    div#import-form {
        height: 250px;
    }

    div#import-form textarea {
        width: 20vw;
    }
}

@media screen and (min-width: 769px) and (max-width: 1800px) {
    div#import-form {
        height: 200px;
    }

    div#import-form textarea {
        width: 14vw;
        margin-left: 3vw;
    }
}
textarea {
  width: 8vw;
  height: 10vh;
  margin-left: 3vw;
  margin-top: 1vh;
  resize: none;
}

.container {
  background-color: rgba(135, 173, 182, 0.6);
  -webkit-filter: brightness(1.4);
          filter: brightness(1.4);
  width: 100vw;
}

.flexContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

.title {
  width: 100%;
  margin: 4vh 0 4vh 0;
  font-size: 30px;
  text-align: center;
  color: black;
}

.contactContainer {
  width: 30%;
  margin: 4vh 5vw 4vh 5vw;
}

.name {
  text-align: center;
}

.imageContainer {
  margin: auto;
  width: 30vw;
  height: 45vh;
}

img {
  display: block;
  margin: auto;
  height: 40vh;
  width: 40vh;
  border-radius: 50%;
  object-fit: cover;
  -webkit-filter: brightness(0.8);
          filter: brightness(0.8);
}

@media screen and (max-width: 992px) {
    .flexContainer {
        -webkit-flex-direction: column;
                flex-direction: column;
    }

    .contactContainer {
        width: 85vw;
        margin: auto;
        margin-bottom: 5vh;
    }
    
    .imageContainer {
        width: 85vw;
    }
}

@media screen and (min-width: 993px) and (max-width: 1800px) {
    .contactContainer {
        width: 90vw;
    }

    .imageContainer {
        width: 35vw;
    }
}
footer {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    
    background: rgba(135, 173, 182, 1.0);
    width: 100vw;
    height: 20vh;

    bottom: 0;
    right: 0;

    font-weight: 300;
    font-size: 1.3rem;
}

footer div {
    height: 20vh;
    width: 10vw;
    margin-right: 5vw;
}

footer i {
    font-size: 1.4em;
    margin-right: -5vw;
}

footer h4, 
footer h4 > a {
    color: white;
}

footer h4 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    margin-top: 1vh;
}

footer a:hover {
    color: #ffa6a0;
}

small {
    color: floralwhite;
}
@media (max-width: 576px) {
    footer {
        -webkit-flex-direction: column;
                flex-direction: column;

        height: 30vh;
    }

    footer div {
        width: 50vw;
        margin: auto;
    }

    footer i {
        margin-right: -30vw;
    }

    small {
        display: none;
    }
}

@media screen and (min-width: 577px) and (max-width: 768px) {
    footer div {
        width: 23vw;
    }

    footer i {
        margin-right: -10vw;
    }
}

@media screen and (min-width: 769px) and (max-width: 1800px) {
    footer div {
        width: 20vw;
    }

    footer i {
        margin-right: -12vw;
    }
}
section.start {
    background: rgba(135, 173, 182, 0.6);
    -webkit-filter: brightness(1.4);
            filter: brightness(1.4);
    padding-bottom: 40px;
    height: 500px;
}

.landing {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;

    padding-top: 80px;
    padding-left: 20vw;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    width: 65vw;
    height: 400px;
}

.landing-text {
    width: 40vw;
    margin-bottom: 65px;
} 

.landing-text h1, .landing-text p {
    font-size: 1.5rem;
    color: black;
}

.landing-text h1 {
    font-size: 2rem;
    margin-bottom: 40px;
}

.intro {
    text-align: center;
    height: 300px;
    width: 55vw;
    margin: auto;
    font-size: 1.5rem;
}

.intro p, .intro h2 {
    color:rgba(71, 100, 106, 1.0);
}

.intro h2 {
    margin-bottom: 40px;
}

section#tutorial {
    width: 100vw; 
    height: 1400px;
    background: rgba(71, 100, 106, 0.6);
    -webkit-filter: brightness(1.7);
            filter: brightness(1.7);
    overflow-y: hidden;
}

#sim {
    height: 330px;
    width: 25vw;
    margin-left: 15vw;
    margin-bottom: 70px;
    background: white;
}

.top {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;

    width: 70vw;
    margin-left: 11vw;
    margin-top: 80px;
    margin-bottom: 80px;
}

.alt {
    width: 60vw;
    margin-left: 20vw;
}

.top section {
    margin-left: 5vw;
}

.top section h3, section p {
    color: white;
}

.top section h3 {
    font-size: 1.8rem;
    margin-bottom: 25px;
}

.top section p {
    font-size: 1.4rem;
    width: 30vw;
}

.fig {
    background: white;
    height: 340px; 
    width: 40vw;
    overflow: hidden;
}

.fig img {
    width: 20vw;
    height: 340px;
    object-fit: center;
}

@media screen and (max-width: 576px) {
    section.start {
        height: 550px;
        width: 100vw;

        padding-top: 50px;

        text-align: center;
    }

    .landing {
        -webkit-flex-direction: column;
                flex-direction: column;
        height: 100vh;
        padding: 0;
        padding-left: 20vw;
    }

    .landing-text {
        height: 600px;
        width: 60vw;
        margin: auto;
    }

    div#sim {
        display: none;
    }

    .intro {
        height: 400px;
        width: 80vw;
    }

    #tutorial {
        height: 1400px;
        padding-left: 10vw;
    }

    .top {
        -webkit-flex-direction: column;
                flex-direction: column;
        text-align: center;
        width: 80vw;
        height: 400px;
        margin: 0;
        padding: 0;

        margin-bottom: 60px;
    }

    .fig {
        height: 20vh;
        width: 40vw;
        margin: auto;
    }

    .fig img {
        height: 20vh;
        width: 40vw;
    }

    div.top section {
        width: 80vw;
    }
    
    div.top section p {
        width: 70vw;
    }

}

@media screen and (min-width: 577px) and (max-width: 768px) {

    section.start {
        height: 450px;
        padding-left: 5vw;
        text-align: center;
    }

    .landing {
        height: 700px;
        width: 50vw;
        margin-top: -10vw;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
    }

    .landing-text {
        height: 450px;
        width: 50vw;
        margin: 0;
    }

    div.action {
        margin: auto;
        -webkit-align-items: center;
                align-items: center;
    }

    div#sim {
        display: none;
    }

    section#tutorial {
        height: 1150px;
    }

    .top {
        margin-bottom: 10px;
    }

    div.intro {
        height: 400px;
    }

    div.top .fig, 
    div.top .fig img {
        margin-top: 30px;
        height: 150px;
        padding-bottom: 40px;
    }
}

@media screen and (min-width: 769px) and (max-width: 1800px) {
    section.start {
        height: 500px;
    }

    .landing {
        height: 500px;
        width: 85vw;

        margin: auto;
        padding: 0;
    }

    .landing-text {
        width: 60vw;

        margin: 0;
        font-size: 0.7em;
    }

    div#sim {
        height: 300px;
        margin-bottom: 20px;
    }

    section#tutorial {
        height: 950px;
    }

    .fig, 
    .fig img {
        height: 200px
    }    

}

nav {
  height: 70px;
  width: 100vw;

  padding-top: 20px;

  display: -webkit-flex;

  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
}

nav h1 {
  font-size: 1.7rem;
  font-weight: 800;
  margin-left: 1vw;
}

nav h1 > Link:hover {
  color: var(--pink);
  cursor: pointer;
}

nav ul.desktop {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;

  width: 50vw;

  padding-top: 5px;
}

nav ul.mobile {
  display: none;
}

nav i.mobile-toggle {
  display: none;
}

nav ul > li {
  font-size: 1.2rem;
}


.mobile {
  display: none;
}
@media (max-width: 576px) {
    nav ul.desktop {
        display: none;
    }

    nav ul.mobile-menu {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        background: white;
        z-index: 999;
        top: 0;
        left: 0;
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        position: fixed;
        text-align: center;
        font-size: 3.0em;
    }

    nav ul.mobile-menu i:hover {
        color: grey;
    }

    nav ul.mobile-menu li {
        font-size: 1.1em;
    }

    nav i.mobile-toggle {
        display: block;
        width: 25vw;
        font-size: 2.0em;
    }
}

@media screen and (min-width: 577px) and (max-width: 768px) {
    nav h1 {
        width: 40vw;
    }
    
    nav ul {
        width: 100vw;
    }

    nav ul li {
        font-size: 1.1em;
    }
}

@media screen and (min-width: 769px) and (max-width: 992px) {
    nav h1 {
        width: 55vw;
    }

    nav ul {
        width: 100vw;
        margin-right: 4vw;
    }
}

nav {
  height: 70px;
  width: 100vw;

  padding-top: 20px;

  display: flex;
  flex-direction: row;
  justify-content: center;
}

nav h1 {
  font-size: 1.7rem;
  font-weight: 800;
  margin-left: 1vw;
}

nav h1 > Link:hover {
  color: var(--pink);
  cursor: pointer;
}

nav ul.desktop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 50vw;

  padding-top: 5px;
}

nav ul.mobile {
  display: none;
}

nav i.mobile-toggle {
  display: none;
}

nav ul > li {
  font-size: 1.2rem;
}


.mobile {
  display: none;
}
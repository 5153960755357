section.start {
    background: rgba(135, 173, 182, 0.6);
    filter: brightness(1.4);
    padding-bottom: 40px;
    height: 500px;
}

.landing {
    display: flex;
    flex-direction: row;
    justify-content: center;

    padding-top: 80px;
    padding-left: 20vw;
    justify-content: space-between;
    width: 65vw;
    height: 400px;
}

.landing-text {
    width: 40vw;
    margin-bottom: 65px;
} 

.landing-text h1, .landing-text p {
    font-size: 1.5rem;
    color: black;
}

.landing-text h1 {
    font-size: 2rem;
    margin-bottom: 40px;
}

.intro {
    text-align: center;
    height: 300px;
    width: 55vw;
    margin: auto;
    font-size: 1.5rem;
}

.intro p, .intro h2 {
    color:rgba(71, 100, 106, 1.0);
}

.intro h2 {
    margin-bottom: 40px;
}

section#tutorial {
    width: 100vw; 
    height: 1400px;
    background: rgba(71, 100, 106, 0.6);
    filter: brightness(1.7);
    overflow-y: hidden;
}

#sim {
    height: 330px;
    width: 25vw;
    margin-left: 15vw;
    margin-bottom: 70px;
    background: white;
}

.top {
    display: flex;
    flex-direction: row;
    justify-content: center;

    width: 70vw;
    margin-left: 11vw;
    margin-top: 80px;
    margin-bottom: 80px;
}

.alt {
    width: 60vw;
    margin-left: 20vw;
}

.top section {
    margin-left: 5vw;
}

.top section h3, section p {
    color: white;
}

.top section h3 {
    font-size: 1.8rem;
    margin-bottom: 25px;
}

.top section p {
    font-size: 1.4rem;
    width: 30vw;
}

.fig {
    background: white;
    height: 340px; 
    width: 40vw;
    overflow: hidden;
}

.fig img {
    width: 20vw;
    height: 340px;
    object-fit: center;
}

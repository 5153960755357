button {
    height: 35px;
    width: 4.5vw;
    border-radius: 11px;
    border: white;
    margin-top: 27px;
    margin-left: 0.5vw;
}

button:hover, button:focus {
    cursor: pointer;
    transform: scale(1.05);
}

#algorithms button {
    width: 13vw;
    height: 55px;
    font-size: 1.1rem;
}

#presets button {
    height: 55px; 
    width: 12vw;
    font-size: 1.0rem;
}

#presets button:last-child {
    width: 7vw;
    margin-left: 4vw;
}

.fns button {
    height: 40px;
    width: 9vw;
    margin-right: 4vw;
    font-size: 1.2rem;
}

.tabs button {
    height: 30px;
    width: 7vw;
    margin-right: 1vw;
    padding-bottom: 5px;
    border-radius: 11px 11px 0 0;
}

button.inactiveTab {
    background: #b9D7EA;
}

button.activeTab {
    background: rgb(239, 239, 239);
}

button.app-button {
    background: #33e958;
    filter: brightness(0.833);
    width: 12vw;
    height: 6vh;
    font-size: 1.5rem;
}

button.app-button:hover {
    cursor: pointer;
}

button.app-button a:hover {
    color: black;
}

button.redirect-mobile {
    display: none;
}
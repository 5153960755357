@media screen and (max-width: 576px) {
    section.start {
        height: 550px;
        width: 100vw;

        padding-top: 50px;

        text-align: center;
    }

    .landing {
        flex-direction: column;
        height: 100vh;
        padding: 0;
        padding-left: 20vw;
    }

    .landing-text {
        height: 600px;
        width: 60vw;
        margin: auto;
    }

    div#sim {
        display: none;
    }

    .intro {
        height: 400px;
        width: 80vw;
    }

    #tutorial {
        height: 1400px;
        padding-left: 10vw;
    }

    .top {
        flex-direction: column;
        text-align: center;
        width: 80vw;
        height: 400px;
        margin: 0;
        padding: 0;

        margin-bottom: 60px;
    }

    .fig {
        height: 20vh;
        width: 40vw;
        margin: auto;
    }

    .fig img {
        height: 20vh;
        width: 40vw;
    }

    div.top section {
        width: 80vw;
    }
    
    div.top section p {
        width: 70vw;
    }

}

@media screen and (min-width: 577px) and (max-width: 768px) {

    section.start {
        height: 450px;
        padding-left: 5vw;
        text-align: center;
    }

    .landing {
        height: 700px;
        width: 50vw;
        margin-top: -10vw;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .landing-text {
        height: 450px;
        width: 50vw;
        margin: 0;
    }

    div.action {
        margin: auto;
        align-items: center;
    }

    div#sim {
        display: none;
    }

    section#tutorial {
        height: 1150px;
    }

    .top {
        margin-bottom: 10px;
    }

    div.intro {
        height: 400px;
    }

    div.top .fig, 
    div.top .fig img {
        margin-top: 30px;
        height: 150px;
        padding-bottom: 40px;
    }
}

@media screen and (min-width: 769px) and (max-width: 1800px) {
    section.start {
        height: 500px;
    }

    .landing {
        height: 500px;
        width: 85vw;

        margin: auto;
        padding: 0;
    }

    .landing-text {
        width: 60vw;

        margin: 0;
        font-size: 0.7em;
    }

    div#sim {
        height: 300px;
        margin-bottom: 20px;
    }

    section#tutorial {
        height: 950px;
    }

    .fig, 
    .fig img {
        height: 200px
    }    

}

@media (max-width: 576px) {
    nav ul.desktop {
        display: none;
    }

    nav ul.mobile-menu {
        display: flex;
        flex-direction: column;
        background: white;
        z-index: 999;
        top: 0;
        left: 0;
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        position: fixed;
        text-align: center;
        font-size: 3.0em;
    }

    nav ul.mobile-menu i:hover {
        color: grey;
    }

    nav ul.mobile-menu li {
        font-size: 1.1em;
    }

    nav i.mobile-toggle {
        display: block;
        width: 25vw;
        font-size: 2.0em;
    }
}

@media screen and (min-width: 577px) and (max-width: 768px) {
    nav h1 {
        width: 40vw;
    }
    
    nav ul {
        width: 100vw;
    }

    nav ul li {
        font-size: 1.1em;
    }
}

@media screen and (min-width: 769px) and (max-width: 992px) {
    nav h1 {
        width: 55vw;
    }

    nav ul {
        width: 100vw;
        margin-right: 4vw;
    }
}